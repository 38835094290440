<script>
export default {
  name: "AboutPage",
  methods: {
    goToContact() {
      this.$router.push({ path: '/contact' });
    }
  }
};

</script>
<template>
  <section class="bg-slate-100 py-12 px-6 space-y-16">
    <!-- Hero Section -->
    <div class="max-w-6xl mx-auto text-center space-y-6">
      <h1 class="text-4xl font-bold">Soy Andrea Thomé: autora de mi vida, autora de espacios transformadores</h1>
    </div>

<!-- Introducción -->
<div class="max-w-5xl mx-auto space-y-6">
  <div class="flex flex-col md:flex-row md:space-x-6 items-stretch text-left">
    
    <!-- Columna de Texto Izquierda -->
    <div class="w-full md:w-1/2 space-y-4 flex flex-col justify-between">
      <div>
        <h2 class="text-2xl font-semibold mb-2">Una vocación que creció conmigo</h2>
        <p>
            Desde pequeña, siempre tuve una conexión especial con los espacios. Mientras otros niños jugaban con sus juguetes, yo jugaba a organizar mi dormitorio como si fuera una casa, creando zonas dedicadas a cada actividad. Esta pasión por el orden y la armonía fue mucho más que un simple juego:
          <span class="font-bold">fue el comienzo de un viaje que me ha llevado a transformar hogares y vidas por más de 30 años.</span>
        </p>
      </div>

      <div>
        <h2 class="text-2xl font-semibold mb-2">Mi interés por organizar no se limitaba a mi dormitorio</h2>
        <p>
            Desde muy joven, también ayudaba a los negocios locales de mi barrio a reorganizar sus espacios para que fueran más funcionales y agradables.
            A los 17 años, decidí seguir mi pasión y me mudé a Buenos Aires para estudiar Diseño de Interiores. Fue allí donde descubrí que mi verdadero propósito no era solo crear espacios bonitos, sino

          <span class="font-bold">ayudar a las personas a encontrar bienestar y equilibrio a través de la organización y armonización</span> de sus entornos.
        </p>
      </div>
    </div>

    <!-- Columna de Imagen Derecha -->
    <div class="w-full md:w-1/2 flex justify-center md:items-center">
      <img src="../../assets/andrea.jpeg" alt="Foto de Andrea Thomé" class="rounded-lg shadow-lg w-full md:w-3/4 lg:w-2/3 max-h-84 object-cover" />
    </div>
  </div>
</div>





    <!-- Emprendimiento -->
    <div class="max-w-6xl mx-auto space-y-6">
      <h2 class="text-4xl font-bold text-center">El nacimiento de mi emprendimiento: Artidea</h2>
      <p class="leading-relaxed text-center text-lg md:text-xl">
        En 1989, me mudé a Uruguay, donde comencé mi propio emprendimiento llamado Artidea. Durante más de 30 años, <span class="font-bold">mi empresa de interiores se convirtió en una referencia en la región</span>, transformando hogares, oficinas y espacios comerciales en lugares llenos de armonía y propósito. 
      </p>
      <p class="leading-relaxed text-center text-lg md:text-xl">
        Mi enfoque siempre ha sido el mismo: diseñar espacios que no solo sean funcionales y estéticamente atractivos, sino que también reflejen la identidad única de cada persona.
      </p>
      <blockquote class="text-lg md:text-xl italic font-semibold text-red-600 bg-white rounded-lg shadow-md py-4 px-8 text-center">
        "La mejor forma de predecir el futuro es crearlo." - Peter Drucker
      </blockquote>
    </div>

    <!-- Formación -->
    <div class="max-w-5xl mx-auto space-y-6 text-center">
      <h2 class="text-4xl font-bold">Aprendí de los mejores</h2>
      <p class="leading-relaxed text-lg md:text-xl">
        A lo largo de los años, he seguido perfeccionando mis habilidades. Mi curiosidad y deseo de ofrecer lo mejor a mis clientes me llevaron a formarme con los mejores del sector en Italia, Inglaterra, Francia y España.
      </p>
      <h3 class="text-lg md:text-xl">
        Estos aprendizajes me permitieron integrar enfoques innovadores y técnicas tradicionales que aplico en cada proyecto. 
      </h3>
      <p class="leading-relaxed text-lg md:text-xl">
        A día de hoy, sigo aprendiendo y adaptando mis conocimientos para asegurar que cada espacio que diseño no solo sea moderno, sino que también promueva el bienestar físico y emocional de quienes lo habitan.
      </p>
    </div>

    <!-- Misión -->
    <div class="max-w-5xl mx-auto text-center space-y-6">
      <h2 class="text-4xl font-bold">Mi misión: bienestar a través del espacio</h2>
      <p class="leading-relaxed text-lg md:text-xl">
        He tenido claro mi propósito de vida desde siempre: ayudar a las personas a crear ambientes que les empoderen y que promuevan su bienestar. Creo firmemente que <span class="font-bold">el espacio en el que vivimos tiene un impacto directo en cómo nos sentimos, cómo nos relacionamos con los demás y cómo enfrentamos los retos de la vida.</span>
      </p>
      <p class="leading-relaxed text-lg md:text-xl">
        A través del Feng Shui tradicional, el diseño interior y mi propio método "3 de 3", trabajo para crear espacios donde la energía fluya libremente y cada rincón refleje lo mejor de tu identidad. ¿Empezamos a diseñar tu espacio juntos?
      </p>
      <button @click="goToContact" class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded-lg transition-transform transform hover:scale-105 cursor-pointer">
        ¡Sí, quiero contar contigo!
      </button>
    </div>

    <!-- Experiencia y Diferenciales -->
    <div class="max-w-5xl mx-auto space-y-6 text-center">
    <h2 class="text-4xl font-bold mb-5">¿Por qué trabajar conmigo?</h2>

    <!-- Contenedor de 3 Columnas -->
    <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <!-- Columna 1 -->
        <div class="flex flex-col items-center space-y-3">
        <i class="fas fa-calendar-check text-red-600 text-4xl"></i>
        <span class="text-lg font-semibold">Más de 30 años de experiencia</span>
        <p class="leading-relaxed">Mi trayectoria me ha permitido transformar cientos de espacios y vidas.</p>
        </div>
        <!-- Columna 2 -->
        <div class="flex flex-col items-center space-y-3">
        <i class="fas fa-star text-red-600 text-4xl"></i>
        <span class="text-lg font-semibold">Método exclusivo</span>
        <p class="leading-relaxed">Mi enfoque único integra el Feng Shui, el diseño interior y la autoayuda, asegurando una transformación completa.</p>
        </div>
        <!-- Columna 3 -->
        <div class="flex flex-col items-center space-y-3">
        <i class="fas fa-globe text-red-600 text-4xl"></i>
        <span class="text-lg font-semibold">Formación internacional</span>
        <p class="leading-relaxed">Me he formado con expertos en Italia, Inglaterra, Francia y España, lo que me ha dado una visión global y moderna del diseño interior.</p>
        </div>
    </div>

    <!-- Descripción y Botón CTA -->
    <p class="leading-relaxed my-4">
        Hoy, desde España, continúo mi labor de transformar espacios y vidas. Estoy aquí para ayudarte a descubrir que <span class="font-bold">tu hogar es mucho más que el lugar donde vives: es el reflejo de lo que eres, un templo de bienestar y equilibrio.</span> Y mi misión es ayudarte a descubrir ese potencial.
    </p>
    <button @click="goToContact" class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded-lg transition-transform transform hover:scale-105 cursor-pointer">
        ¡Empecemos!
    </button>
    </div>

  </section>
</template>
