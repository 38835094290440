<template>
  <div id="app" class="flex flex-col min-h-screen">
    <Splash /> <!-- Splash Screen añadido -->

    <!-- Navbar siempre visible -->
    <NavbarComponent />

    <!-- Contenido dinámico de cada página -->
    <main class="flex-grow">
      <router-view />
    </main>

    <!-- Footer siempre visible al final -->
    <FooterComponent />
  </div>
</template>

<script>
import NavbarComponent from '@/components/NavBar.vue';
import FooterComponent from '@/components/Footer.vue';
import Splash from '@/components/Splash.vue';

export default {
  name: 'App',
  components: {
    NavbarComponent,
    FooterComponent,
    Splash,
  },
};
</script>

<style>
#app {
  font-family: Arial, Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-padding-top: 100px;
}
</style>
