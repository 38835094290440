<template>
  <div class="pt-28 min-h-screen flex flex-col">
    <AboutMeView class="flex-grow" />
  </div>
</template>

<script>
import AboutMeView from '@/components/AboutMeView/index.vue';

export default {
  name: "AboutMePage",
  components: {
    AboutMeView,
  },
};
</script>
