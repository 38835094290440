<template>
  <section id="empowerHome" class="pt-24">
    <!-- Sección Hero -->
    <div class="bg-cover bg-center py-20" style="background-image: url('/path/to/hero-image.jpg');">
      <div class="max-w-6xl mx-auto text-center">
        <h1 class="text-4xl font-bold mb-6">Crea tu espacio: diseña un hogar que te empodere</h1>
        <button @click="goToContact" class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded-lg transition-transform transform hover:scale-105">
          Quiero saber más
        </button>
      </div>
    </div>

    <!-- Sección Introductoria -->
    <div class="bg-slate-100 py-12 px-6">
      <div class="max-w-5xl mx-auto text-center">
        <h2 class="text-3xl font-semibold mb-4">¿Quieres que el bienestar llame a tu puerta?</h2>
        <p class="leading-relaxed">
          Cada espacio tiene el poder de influir en tu energía, en cómo te sientes y cómo te enfrentas al día a día. Mi enfoque no es simplemente reorganizar muebles o mejorar la decoración; mi misión es enseñarte a diseñar un entorno que potencie tu bienestar, que te proporcione empoderamiento y conexión con tu hogar. A través de mi método exclusivo "3 de 3", te guiaré para que puedas convertir cualquier espacio en un reflejo de tu energía, tus gustos y tus metas.        </p>
      </div>
    </div>


    <div class="py-12 px-6">
      <div class="max-w-5xl mx-auto">
        <h2 class="text-3xl font-semibold mb-4 text-center">¿Cómo lo vamos a hacer?</h2>
        <!-- Sección de Servicios con Tarjetas -->
        <div class="max-w-6xl mx-auto grid gap-8 md:grid-cols-2 py-4 px-6">
          <!-- Servicio 1: Feng Shui -->
          <div class="p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow space-y-6">
            <h3 class="text-2xl font-semibold text-red-600">Organizando con Feng Shui: canaliza la energía de tu hogar</h3>
            <!-- Descripción Principal -->
            <p class="leading-relaxed">
              El Feng Shui es mucho más que una técnica de organización: es una filosofía milenaria que te enseña a entender cómo la disposición de los objetos en tu hogar influye en tu bienestar. Mi servicio de Feng Shui te ayudará a redescubrir tu espacio y a optimizarlo para que la energía fluya de manera natural, desbloqueando zonas estancadas y permitiendo que todo en tu vida funcione en armonía.
            </p>
            <!-- Descripción Adicional -->
            <p class="font-semibold">Con este servicio aprenderás a:</p>
            <!-- Lista de Beneficios con Iconos -->
            <ul class="space-y-4">
              <li class="flex items-start space-x-3">
                <i class="fas fa-compass text-red-600 text-xl"></i>
                <span class="leading-relaxed">Identificar las áreas clave de tu hogar que necesitan equilibrio energético</span>
              </li>
              <li class="flex items-start space-x-3">
                <i class="fas fa-couch text-red-600 text-xl"></i>
                <span class="leading-relaxed">Reorganizar los muebles y objetos de manera estratégica para mejorar la circulación de energía</span>
              </li>
              <li class="flex items-start space-x-3">
                <i class="fas fa-palette text-red-600 text-xl"></i>
                <span class="leading-relaxed">Elegir los colores y materiales adecuados para cada estancia, potenciando la energía positiva y tu bienestar</span>
              </li>
              <li class="flex items-start space-x-3">
                <i class="fas fa-wind text-red-600 text-xl"></i>
                <span class="leading-relaxed">Tu hogar será un lugar donde la energía fluya libremente, permitiendo que te sientas en paz y en control de tu entorno</span>
              </li>
            </ul>
          </div>

          <div class="p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow space-y-6">
            <h3 class="text-2xl font-semibold text-red-600">Domótica y bienestar: tecnología al servicio de tu espacio</h3>

            <!-- Descripción Principal -->
            <p class="leading-relaxed">
              La tecnología no tiene por qué ser fría o ajena a la armonía de tu hogar. Mi servicio de integración de domótica te permite crear un entorno inteligente, donde la tecnología y el bienestar energético se unen. Imagina poder ajustar la luz, el clima o incluso la seguridad de tu hogar con un simple toque, todo mientras mantienes el equilibrio energético que aporta el Feng Shui.
            </p>

            <!-- Subtítulo -->
            <p class="font-semibold">¿Cómo influirá la domótica en tu espacio?</p>

            <!-- Lista de Beneficios con Iconos -->
            <ul class="space-y-4">
              <li class="flex items-start space-x-3">
                <i class="fas fa-lightbulb text-red-600 text-xl"></i>
                <span class="leading-relaxed">
                  <strong>Comodidad y control:</strong> Automatiza funciones como la iluminación y la temperatura para adaptarlas a tu ritmo de vida, todo sin interrumpir el flujo de energía.
                </span>
              </li>
              <li class="flex items-start space-x-3">
                <i class="fas fa-leaf text-red-600 text-xl"></i>
                <span class="leading-relaxed">
                  <strong>Eficiencia energética:</strong> Optimiza el consumo energético de tu hogar mientras mantienes un entorno armónico.
                </span>
              </li>
              <li class="flex items-start space-x-3">
                <i class="fas fa-paint-brush text-red-600 text-xl"></i>
                <span class="leading-relaxed">
                  <strong>Funcionalidad con estilo:</strong> La tecnología no tiene por qué sacrificar el diseño. Te ayudaré a integrar la domótica de manera que se complemente con el diseño y la energía de tu espacio.
                </span>
              </li>
            </ul>

            <!-- Mensaje Final -->
            <p class="leading-relaxed">
              Este servicio es perfecto si buscas elevar tu hogar a un nuevo nivel de comodidad y eficiencia, manteniendo siempre el equilibrio energético como prioridad.
            </p>
          </div>
        </div>
      </div>
    </div>


    <!-- Sección de Resultados con Fondo Oscuro -->
    <div class="bg-slate-900 text-white py-16 px-6">
      <div class="max-w-5xl mx-auto text-center">
        <h2 class="text-3xl font-semibold mb-6">El resultado: un hogar que te empodera cada día</h2>
        <p class="leading-relaxed mb-6">
          Al final del proceso de renovación, no solo habrás transformado tu hogar en un lugar más organizado o estéticamente agradable. <span class="font-bold">Habrás creado un espacio que hable de ti, que vibre con tu energía y que apoye tu bienestar cada día.</span> Te convertirás en el corazón de tu hogar, y ese cambio también influirá en tu familia, creando un entorno donde todos puedan prosperar.
        </p>
        <p class="leading-relaxed mb-6">
          Transformar tu espacio es un acto de empoderamiento personal. <span class="font-bold">No es un lujo reservado para pocos</span>, sino una herramienta que está al alcance de cualquiera que quiera hacer de su casa un verdadero hogar, un lugar donde la abundancia y el bienestar fluyan de manera natural.
        </p>
        <button @click="goToContact" class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded-lg transition-transform transform hover:scale-105">
          Comienza hoy el futuro de tu hogar
        </button>
      </div>
    </div>

    <!-- Galería de Fotos Antes/Después -->
    <div class="max-w-6xl mx-auto text-center py-16">
      <h2 class="text-3xl font-semibold mb-6">Galería de Transformaciones</h2>
      <!-- Componente de Galería -->
      <!-- <GalleryComponent /> -->
    </div>
  </section>
</template>

<script>
export default {
  name: "EmpowerHomePage",
  methods: {
    goToContact() {
      this.$router.push({ path: '/contact' });
    },
  }
};
</script>
