<script>
// import Domotic from './Domotic.vue';
export default {
  // components: { Domotic },
  name: "VideoComponent",
  data() {
    return {
      
    };
  },

  methods: {}
};
</script>

<template>
   <section class="py-4" id="video">
      <div class="max-w-6xl px-8 mx-auto">
        <div data-aos="fade-left" data-aos-duration="1500" class="flex flex-wrap justify-center mt-5">
          <iframe width="900" height="450" src="https://www.youtube.com/embed/dF5ECNGwo0Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </section>
</template>
  