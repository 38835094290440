<template>
  <div class="pt-28 min-h-screen flex flex-col">
    <BooksView class="flex-grow" />
  </div>
</template>

<script>
import BooksView from '@/components/BooksView/index.vue';

export default {
  name: "BooksPage",
  components: {
    BooksView,
  },
};
</script>
