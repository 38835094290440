<template>
  <div v-if="showSplash" class="fixed inset-0 bg-white flex items-center justify-center overflow-hidden z-50">
    <!-- Panel izquierdo -->
    <div
      class="absolute left-1/2 top-0 h-full bg-red-600 w-1/2 transform origin-left transition-transform duration-1000"
      :class="{ 'translate-x-full': doorsOpen }"
    ></div>

    <!-- Panel derecho -->
    <div
      class="absolute right-1/2 top-0 h-full bg-red-600 w-1/2 transform origin-right transition-transform duration-1000"
      :class="{ '-translate-x-full': doorsOpen }"
    ></div>

    <!-- Filtro de desenfoque que se desvanece gradualmente -->
    <div
      class="absolute inset-0 bg-transparent backdrop-blur-md transition-all duration-1500"
      :class="{ 'opacity-0 backdrop-blur-0': doorsOpen }"
    ></div>

    <!-- Imagen central -->
    <img src="../../public/logo-at-rojo.png" alt="Logo" class="absolute w-40 h-40 animate-fade-in" />
  </div>
</template>

<script>
export default {
  name: 'SplashComponent',
  data() {
    return {
      showSplash: true,
      doorsOpen: false,
    };
  },
  mounted() {
    // Verifica si el splash ya se ha mostrado en esta sesión
    if (sessionStorage.getItem("splashSeen")) {
      this.showSplash = false;
      return;
    }

    // Marca el splash como visto en esta sesión
    sessionStorage.setItem("splashSeen", "true");

    // Inicia la animación de apertura de puertas y desenfoque después de 0.5 segundos
    setTimeout(() => {
      this.doorsOpen = true;
    }, 500);

    // Oculta el splash después de 1.5 segundos
    setTimeout(() => {
      this.showSplash = false;
      this.$emit("hideSplash");
    }, 1500);
  },
};
</script>

<style scoped>
/* Animación de desvanecimiento del texto */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 1.0s ease-in-out;
}
</style>
