<script>
export default {
  name: "Section8Component",
  methods: {
    goToContact() {
      this.$router.push({ path: '/contact' });
    },
  }
};
</script>

<template>
  <section class="py-12" id="section8">
    <div class="max-w-6xl px-6 mx-auto">
      <!-- Título principal -->
      <h2 data-aos="fade-up" class="text-center text-3xl md:text-4xl font-bold text-gray-800 mb-8">
        Conviértete en el corazón de tu espacio
      </h2>

      <!-- Descripción -->
      <p class="text-justify md:text-center text-gray-700 leading-relaxed mb-4">
        Ya sabes: tu hogar es tu templo, tu espacio seguro, tu remanso de paz. Y la abundancia, en cualquier aspecto de tu vida, solo llega cuando tu hogar está listo para recibirla. Este es el gran secreto que he aprendido a lo largo de mi carrera, y quiero compartirlo contigo.
      </p>
      <p class="text-justify md:text-center text-gray-700 leading-relaxed mb-8">
        Te enseñaré a convertir tu casa en un lugar de energía positiva, donde te sientas en paz y listo para atraer todo lo que deseas. ¿Empezamos?
      </p>

      <!-- Botón de llamada a la acción -->
      <div class="text-center mt-10">
        <button @click="goToContact" class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded-lg transition-transform transform hover:scale-105 cursor-pointer">
          ¡Empecemos!
        </button>
      </div>
    </div>
  </section>
</template>
