import Vue from 'vue'
import VueRouter from 'vue-router'
import Portfolio from '../views/Portfolio.vue'
import Empower from '../views/Empower.vue'
import Contact from '../views/Contact.vue'
import AboutMe from '../views/AboutMe.vue'
import Books from '../views/Books.vue'
import Training from '../views/Training.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Portfolio
  },
  {
    path: '/empower',
    name: 'empower',
    component: Empower
  },
  {
    path: '/aboutme',
    name: 'aboutMe',
    component: AboutMe
  },
  {
    path: '/books',
    name: 'books',
    component: Books
  },
  {
    path: '/training',
    name: 'training',
    component: Training
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
