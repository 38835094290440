<template>
  <div class="bg-slate-100 pt-20" id="contact">
    <div class="max-w-6xl mx-auto px-8">
      <h1 data-aos="fade-up" class="relative text-center text-4xl font-bold my-3">
        Hablemos de tu espacio, de tus sueños
      </h1>
      <h2 data-aos="fade-up" class="text-center text-2xl font-bold">
        Da el primer paso hacia un hogar lleno de armonía aquí
      </h2>
      <h2 data-aos="fade-up" class="text-center text-4xl font-bold my-5">
        ¡Hablemos!
      </h2>
      <p class="text-center">
        Si sientes que tu hogar está listo para una transformación que refleje lo mejor de ti, estoy aquí para ayudarte a dar ese primer paso. A través de mi método "3 de 3" y mi experiencia en Feng Shui y diseño interior, quiero guiarte en este camino hacia un espacio que hable de tus sueños y potencie tu bienestar.
      </p>
      <p class="text-center my-2">
        Tanto si tienes una pregunta puntual como si quieres comenzar una transformación completa, estaré encantada de conocerte y saber más sobre lo que necesitas. 
      </p>
      <p class="text-center font-bold my-2">
        Este es tu momento de hacer de tu hogar un lugar donde te sientas realmente en paz y en armonía.
      </p>
      <p class="text-center my-2">
        ¡Estoy aquí para escucharte y ayudarte a hacerlo posible!
      </p>
      <div data-aos="fade-right" data-aos-duration="1500" class="flex flex-wrap justify-between my-5">
        <div class="w-full md:w-1/2 mb-8 md:mb-0">
          <div class="text-xl font-semibold mb-4">
            Escríbeme
          </div>
          <div class="space-y-1">
            <div class="flex items-center h-16">
              <i class="fas fa-user text-red-600 text-2xl"></i>
              <div class="ml-6">
                <p>Andrea Thomé</p>
              </div>
            </div>
            <div class="flex items-center h-16">
              <i class="fas fa-map-marker-alt text-red-600 text-2xl"></i>
              <div class="ml-6">
                <p>Barcelona, España</p>
              </div>
            </div>
            <div class="flex items-center h-16">
              <i class="fas fa-envelope text-red-600 text-2xl"></i>
              <div class="ml-6">
                <p>andrea@andreathome.es</p>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/2">
          <form @submit.prevent="sendData">
            <input type="hidden" name="_subject" value="Nuevo mensaje de contacto">
            <!-- Redirige automáticamente después de enviar -->
            <input type="hidden" name="_next" value="https://localhost:8080/"> <!-- Cambia la URL a la tuya -->
            <div class="flex space-x-4 mb-4">
              <input type="text" class="border border-gray-400 rounded-lg w-1/2 p-3" placeholder="Nombre" name="name" v-model="name" required />
              <input type="text" class="border border-gray-400 rounded-lg w-1/2 p-3" placeholder="Apellidos" name="surnames" v-model="surnames" required />
            </div>
            <div class="mb-4">
              <input type="email" class="border border-gray-400 rounded-lg w-full p-3" placeholder="Correo electrónico" name="email" v-model="email" required />
            </div>
            <div class="mb-4">
              <input type="text" class="border border-gray-400 rounded-lg w-full p-3" placeholder="Asunto" name="subject" v-model="subject" required />
            </div>
            <div class="mb-4">
              <textarea class="border border-gray-400 rounded-lg w-full p-3 resize-none" rows="5" placeholder="Mensaje..." name="message" v-model="body" required></textarea>
            </div>
            <div>
              <button type="submit" class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded-lg transition-transform transform hover:scale-105 cursor-pointer" :disabled="isLoading">
                <template v-if="isLoading">
                  <svg class="animate-spin h-5 w-5 text-white mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                  </svg>
                  Enviando...
                </template>
                <span v-else>Enviar mensaje</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <ModalComponent title="Mensaje enviado correctamente"/>
  </div>
</template>

<script>
import axios from 'axios';
import { showAlert } from '../plugins/alerts';

export default {
  name: "ContactComponent",
  data() {
    return {
      name: '',
      surnames: '',
      email: '',
      subject: '',
      body: '',
      isLoading: false,
    };
  },
  methods: {
    sendData() {
      this.isLoading = true;
      // Objeto JSON que necesitas enviar
      const data = {
        contactName: this.name,
        contactLastName: this.surnames,
        contactEmail: this.email,
        subject: this.subject,
        message: this.body
      };

      // Realizar la solicitud POST
      axios
          .post('https://api.ballthor.com/api/public/andrea-lead', data)
          .then((response) => {
            console.log('Datos enviados con éxito:', response.data);
            this.isLoading = false; // Cambiar la variable a false cuando se complete
            this.handleClick('Mensaje enviado correctamente', '', 'success')
            this.resetFields()
          })
          .catch((error) => {
            console.error('Error al enviar los datos:', error);
            this.isLoading = false; // Cambiar la variable a false incluso si hay un error
            this.handleClick('Error al enviar el mensaje', '', 'warning')
          });
    },
    handleClick(title, text, icon) {
      showAlert(title, text, icon);
    },
    resetFields() {
      this.name = '',
      this.surnames = '',
      this.email = '',
      this.subject = '',
      this.body = ''
    }
  },
};
</script>
