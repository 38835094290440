<script>
export default {
  name: "DomoticComponent",
  methods: {
    goToContact() {
      this.$router.push({ path: '/contact' });
    },
  }
};
</script>

<template>
  <section class="bg-slate-100 py-12" id="section7">
    <div class="max-w-6xl px-6 mx-auto">
      <!-- Título principal -->
      <h2 data-aos="fade-up" class="text-center text-3xl md:text-4xl font-bold text-gray-800 mb-8">
        Y ahora, ¿cómo mejorará tu vida con mi método?
      </h2>

      <!-- Bloque 1: Liberarás el potencial de tu hogar -->
      <div class="mb-8">
        <h3 data-aos="fade-up" class="text-center text-red-600 text-2xl font-bold mb-4">
          Liberarás el potencial de tu hogar
        </h3>
        <p class="text-justify md:text-center text-gray-700 leading-relaxed">
          Con mi ayuda, vas a crear un espacio que no solo funcione mejor, sino que también refleje lo mejor de ti. ¿Sabías que cuando tu hogar está alineado con tu energía, se convierte en un catalizador para la abundancia y el bienestar?
          Sentirás cómo tu energía fluye sin restricciones, y eso se reflejará en cada aspecto de tu vida.
        </p>
      </div>

      <!-- Bloque 2: Inspirarás a tu familia -->
      <div class="mb-8">
        <h3 data-aos="fade-up" class="text-center text-red-600 text-2xl font-bold mb-4">
          Inspirarás a tu familia
        </h3>
        <p class="text-justify md:text-center text-gray-700 leading-relaxed">
          No solo tú te beneficiarás. Al organizar tu espacio y alinearlo con el Feng Shui, también inspirarás a los miembros de tu familia. Un hogar bien organizado y lleno de energía positiva es el entorno perfecto para nutrir relaciones saludables y fortalecer la unión familiar.
        </p>
      </div>

      <!-- Botón de llamada a la acción -->
      <div class="text-center mt-10">
        <button @click="goToContact" class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded-lg transition-transform transform hover:scale-105 cursor-pointer">
          Cuéntame más
        </button>
      </div>
    </div>
  </section>
</template>
