<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<template>
  <footer class="flex flex-col md:flex-row items-center justify-between bg-gray-800 px-6 py-4 text-white">
    <!-- Derechos de autor -->
    <div class="text-center md:text-left mb-2 md:mb-0">
      <span>&copy; Andrea Thomé | {{ currentYear }}</span>
    </div>
    
    <!-- Enlaces sociales y contacto -->
    <div class="flex flex-col md:flex-row items-center md:space-x-6 space-y-2 md:space-y-0 text-center">
      <div class="flex space-x-4">
        <a target="_blank" href="https://es.linkedin.com/in/andrea-thom%C3%A9-6aba383b" title="LinkedIn" class="text-white hover:text-gray-300 transition-all">
          <i class="fab fa-linkedin-in fa-xl"></i>
        </a>
        <a target="_blank" href="https://www.instagram.com/byandreathome/" title="Instagram" class="text-white hover:text-gray-300 transition-all">
          <i class="fab fa-instagram fa-xl"></i>
        </a>
      </div>
      <span class="text-gray-300">andrea@andreathome.es</span>
    </div>
  </footer>
</template>
