<script>
export default {
  name: "DomoticComponent",
};
</script>

<template>
  <section class="py-12" id="section4">
    <div class="max-w-6xl px-6 mx-auto">
      <!-- Título principal -->
      <h2 data-aos="fade-up" class="text-center text-3xl md:text-4xl font-bold text-gray-800 mb-8">
        Supera los obstáculos y transforma tu espacio
      </h2>
      <p class="text-justify md:text-center text-gray-700 leading-relaxed mb-12">
        Sé que dar el primer paso puede generar dudas, pero no estás solo en este proceso. Mi objetivo es guiarte y ayudarte a superar cualquier barrera que te impida organizar y transformar tu hogar.
        Sigue leyendo si quieres avanzar con confianza y empezar a crear un espacio lleno de armonía y bienestar. Estás en buenas manos.
      </p>

      <!-- Tarjetas de contenido -->
      <div data-aos="fade-left" data-aos-duration="1500" class="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        <!-- Card 1 -->
        <div class="bg-white p-6 rounded-lg shadow-lg">
          <h3 class="text-lg font-bold text-red-600 mb-2">¿Tienes miedo de no poder hacerlo?</h3>
          <p class="text-gray-700 leading-relaxed">
            No te preocupes, mi misión es hacer que pierdas el miedo. A menudo, pensamos que organizar o rediseñar nuestro hogar es un lujo, algo inalcanzable.
            Pero mi método está diseñado para que cualquiera pueda hacerlo. Te guiaré paso a paso para que conviertas tu casa en un hogar que refleje tu energía.
          </p>
        </div>

        <!-- Card 2 -->
        <div class="bg-white p-6 rounded-lg shadow-lg">
          <h3 class="text-lg font-bold text-red-600 mb-2">¿No sabes por dónde empezar?</h3>
          <p class="text-gray-700 leading-relaxed">
            La mayoría de las personas no saben cómo comenzar a organizar sus espacios. Eso es normal. Con mi plan, aprenderás a identificar las áreas clave de tu hogar, 
            a zonificar y a crear un flujo de energía que te beneficiará a ti y a tu familia. No es complicado, y yo estaré contigo durante todo el proceso.
          </p>
        </div>

        <!-- Card 3 -->
        <div class="bg-white p-6 rounded-lg shadow-lg">
          <h3 class="text-lg font-bold text-red-600 mb-2">¿Te cuesta conectar con tu hogar?</h3>
          <p class="text-gray-700 leading-relaxed">
            Es común sentir que nuestro hogar no refleja lo que realmente somos. Mi enfoque te ayudará a conectar emocionalmente con tu espacio, haciendo que cada rincón refleje tus emociones, gustos y personalidad.
            Verás cómo tu casa se convierte en una extensión de ti mismo, promoviendo el bienestar en tu vida cotidiana.
          </p>
        </div>

        <!-- Card 4 -->
        <div class="bg-white p-6 rounded-lg shadow-lg">
          <h3 class="text-lg font-bold text-red-600 mb-2">¿Quieres dar más energía a tus espacios y a tu familia?</h3>
          <p class="text-gray-700 leading-relaxed">
            Uno de los temas que te mostraré es todo lo que tienes que tomar en cuenta para crear tu propia energía en tus estancias. 
            Verás desde la zonificación previa creando que la circulación no tenga obstáculos para que tu energía fluya y refleje realmente tu presencia, emoción y resuene a tu familia.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
