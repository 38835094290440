<template>
    <div class="pt-28 min-h-screen flex flex-col">
      <TrainingView class="flex-grow" />
    </div>
  </template>
  
  <script>
  import TrainingView from '@/components/TrainingView/index.vue';
  
  export default {
    name: "AboutMePage",
    components: {
      TrainingView,
    },
  };
  </script>
  