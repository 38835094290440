<template>
  <div class="empower">
    <NavBar />
    <Section1 />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Section1 from "@/components/EmpowerView/Section1.vue";

export default {
  name: "EmpowerView",
  components: {
    NavBar,
    Section1
    
  },
};
</script>
