import Swal from 'sweetalert2';

export function showAlert(title, text, icon) {
  return Swal.fire({
    title: title,
    text: text,
    icon: icon,
    confirmButtonText: 'Aceptar',
    customClass: { 
        confirmButton: 'bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700' 
      }
  });
}
