<template>
  <div class="pt-28 min-h-screen flex flex-col">
    <ContactMe class="flex-grow" />
  </div>
</template>

<script>
import ContactMe from '@/components/ContactMe.vue';

export default {
  name: "ContactPage",
  components: {
    ContactMe,
  },
};
</script>
