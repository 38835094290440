var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"bg-white backdrop-blur-lg shadow-md fixed w-full z-20 top-0"},[_c('div',{staticClass:"container mx-auto flex items-center justify-between px-4 py-3"},[_c('div',{staticClass:"flex justify-center w-full md:w-auto md:justify-start cursor-pointer"},[_c('img',{staticClass:"h-16 md:h-20",attrs:{"src":require("../assets/logo-pro-2.jpg"),"alt":"logo"},on:{"click":_vm.goToHome}})]),_c('button',{staticClass:"inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200",attrs:{"aria-controls":"navbar-sticky","aria-expanded":"false"},on:{"click":function($event){_vm.isActive = !_vm.isActive}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Abrir menú principal")]),_c('svg',{staticClass:"w-6 h-6",attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M3 5h14a1 1 0 110 2H3a1 1 0 110-2zm0 6h14a1 1 0 110 2H3a1 1 0 110-2zm0 6h14a1 1 0 110 2H3a1 1 0 110-2z","clip-rule":"evenodd"}})])]),_c('div',{class:("flex-col md:flex-row " + (_vm.isActive ? 'flex' : 'hidden') + " md:flex w-full md:w-auto"),attrs:{"id":"navbar-sticky"}},[_c('ul',{staticClass:"flex flex-col items-center md:items-center p-4 md:p-0 font-bold space-y-2 md:space-y-0 md:space-x-8 md:flex-row"},[_c('li',[_c('a',{class:[
              'block py-2 px-3 transition duration-200 cursor-pointer focus:outline-none',
              _vm.isActiveRoute('/') ? 'text-red-500 underline' : 'text-black hover:text-red-500 hover:underline'
            ],style:({ color: _vm.isActiveRoute('/') ? '#EF4444' : 'black' }),on:{"click":_vm.goToHome}},[_vm._v(" Inicio ")])]),_c('li',[_c('a',{class:[
              'block py-2 px-3 transition duration-200 cursor-pointer focus:outline-none',
              _vm.isActiveRoute('/books') ? 'text-red-500 underline' : 'text-black hover:text-red-500 hover:underline'
            ],style:({ color: _vm.isActiveRoute('/books') ? '#EF4444' : 'black' }),on:{"click":_vm.goToBooks}},[_vm._v(" Libros ")])]),_c('li',[_c('a',{class:[
              'block py-2 px-3 transition duration-200 cursor-pointer focus:outline-none',
              _vm.isActiveRoute('/training') ? 'text-red-500 underline' : 'text-black hover:text-red-500 hover:underline'
            ],style:({ color: _vm.isActiveRoute('/training') ? '#EF4444' : 'black' }),on:{"click":_vm.goToTraining}},[_vm._v(" Formación ")])]),_c('li',[_c('a',{class:[
              'block py-2 px-3 transition duration-200 cursor-pointer focus:outline-none',
              _vm.isActiveRoute('/aboutme') ? 'text-red-500 underline' : 'text-black hover:text-red-500 hover:underline'
            ],style:({ color: _vm.isActiveRoute('/aboutme') ? '#EF4444' : 'black' }),on:{"click":_vm.goToAboutMe}},[_vm._v(" Sobre mí ")])]),_c('li',[_c('a',{class:[
              'block py-2 px-3 transition duration-200 cursor-pointer focus:outline-none',
              _vm.isActiveRoute('/contact') ? 'text-red-500 underline' : 'text-black hover:text-red-500 hover:underline'
            ],style:({ color: _vm.isActiveRoute('/contact') ? '#EF4444' : 'black' }),on:{"click":_vm.goToContact}},[_vm._v(" Contacto ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }