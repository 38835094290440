<script>
export default {
  name: "BookComponent",
  methods: {
    goToContact() {
      this.$router.push({ path: '/contact' });
    },
  }
};
</script>

<template>
  <section class="py-12 mt-12" id="section2">
    <div class="max-w-6xl px-6 mx-auto">
      <h2 data-aos="fade-up" class="text-center text-3xl md:text-4xl font-bold pb-8">
        INVENTA TU ESPACIO INTERIOR
      </h2>

      <div data-aos="fade-left" data-aos-duration="1500" class="flex flex-col md:flex-row items-center gap-8">
        <!-- Imagen de la sección -->
        <div class="w-full md:w-2/5 flex justify-center">
          <img src="../../assets/librobg.png" alt="Libro imagen">
        </div>

        <!-- Contenido de la sección -->
        <div class="w-full md:w-3/5 text-center md:text-left">
          <h3 class="text-2xl font-semibold text-gray-800 mb-4">
            TU CASA, TU HOGAR, TU TEMPLO
          </h3>
          <p class="text-justify md:text-left text-gray-700 mt-4 leading-relaxed">
            La <span class="font-bold text-red-600">ABUNDANCIA</span> en todo su esplendor y en cualquier aspecto de tu vida, llega cuando tu <span class="font-bold text-red-600">TEMPLO</span> (tu propio hogar) está listo para RECIBIRTE. Ten algo en cuenta y es que… 
            <span class="font-bold">Nadie recibe de afuera lo que no ha sido capaz de crear dentro de casa.</span> Este es el GRAN SECRETO de mi propio éxito y ahora quiero que el mundo se entere.
          </p>
          <p class="text-justify md:text-left text-gray-700 mt-4 leading-relaxed">
            Mis libros contienen mi filosofía de trabajo y ahora son todo tuyos, para que apliques todo mi conocimiento en la salud de tu hogar.
          </p>
          <!-- Botón de llamada a la acción -->
          <div class="mt-8">
            <button @click="goToContact" class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded-lg transition-transform transform hover:scale-105 cursor-pointer">
              Comprar ahora
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
