<script>
export default {
  name: "BookPage",
  methods: {
    goToContact() {
      this.$router.push({ path: "/contact" });
    },
  },
};
</script>

<template>
  <section class="bg-slate-100 py-12 px-6 space-y-16">
    <!-- Hero Section -->
    <div class="max-w-6xl mx-auto text-center space-y-8">
      <h1 class="text-3xl md:text-4xl font-bold">
        La guía definitiva para transformar tu hogar
      </h1>
    </div>

    <div
      class="max-w-7xl mx-auto flex flex-col md:flex-row justify-center items-center gap-8 md:gap-16"
    >
      <!-- Sección de Texto a la Izquierda -->
      <div class="max-w-lg space-y-4 text-left md:text-left">
        <h2 class="text-4xl md:text-4xl font-bold">Casa grande, casa chica</h2>
        <h2 class="text-lg md:text-2xl font-semibold">
          El primer paso hacia un hogar en equilibrio
        </h2>
        <p class="text-lg md:text-xl">
          Si sientes que tu hogar no está alineado con tu esencia y quieres
          descubrir cómo hacerlo fluir en armonía contigo, estás en buen lugar.
        </p>
      </div>

      <!-- Contenedor de los libros, centrado -->
      <div class="flex justify-center items-center relative">
        <!-- Libro izquierdo -->
        <img
          src="../../assets/tapa2.png"
          class="absolute w-72 h-auto transform -translate-x-32 z-0"
        />
        <!-- Libro central -->
        <img src="../../assets/tapa1.png" class="relative w-96 h-auto z-10" />
        <!-- Libro derecho -->
        <img
          src="../../assets/tapa3.png"
          class="absolute w-72 h-auto transform translate-x-32 z-0"
        />
      </div>
    </div>

    <!-- Emprendimiento -->
    <div class="max-w-6xl mx-auto space-y-6">
      <h2 class="text-2xl md:text-3xl font-semibold text-center">
        El arte de vivir en armonía
      </h2>
      <p class="text-center text-lg md:text-xl">
        ¿Te has preguntado alguna vez cómo el entorno en el que vives afecta tu
        bienestar, tus relaciones y hasta tu prosperidad? Con mi serie de
        libros, aprenderás a utilizar el poder del Feng Shui, el diseño y tu
        propia energía para transformar tu hogar en un lugar lleno de equilibrio
        y abundancia.
      </p>
      <p class="text-center text-lg md:text-xl">
        No importa si vives en una casa grande o pequeña: lo que importa es cómo
        organizas y armonizas el espacio para que refleje lo mejor de ti.
      </p>
      <p class="text-center text-lg md:text-xl">
        Mi serie de libros
        <span class="font-bold">Casa grande, casa chica</span> es el inicio de
        tu viaje hacia un hogar que respire equilibrio, paz y tu propia
        personalidad. Aquí encontrarás una guía práctica que te acompañará paso
        a paso en la organización y la transformación de tu espacio.
      </p>
      <p class="text-center text-lg md:text-xl">
        Y, para facilitarte el proceso, este libro no es solo teoría, sino un
        manual para que tomes acción de inmediato:
      </p>

      <!-- Libros -->
      <div class="grid gap-10 md:grid-cols-2 lg:grid-cols-3">
        <div
          class="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow flex flex-col items-center space-y-4"
        >
          <div class="w-full flex justify-center">
            <img
              src="../../assets/tapa2.png"
              alt="Libro imagen"
              class="rounded-lg"
            />
          </div>
          <button
            @click="goToContact"
            class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 w-full rounded transition-transform transform hover:scale-105"
          >
            ¡Lo quiero!
          </button>
        </div>

        <div
          class="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow flex flex-col items-center space-y-4"
        >
          <div class="w-full flex justify-center">
            <img
              src="../../assets/tapa1.png"
              alt="Libro imagen"
              class="rounded-lg"
            />
          </div>
          <button
            @click="goToContact"
            class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 w-full rounded transition-transform transform hover:scale-105"
          >
            ¡Lo quiero!
          </button>
        </div>

        <div
          class="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow flex flex-col items-center space-y-4"
        >
          <div class="w-full flex justify-center">
            <img
              src="../../assets/tapa3.png"
              alt="Libro imagen"
              class="rounded-lg"
            />
          </div>
          <button
            @click="goToContact"
            class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 w-full rounded transition-transform transform hover:scale-105"
          >
            ¡Lo quiero!
          </button>
        </div>
      </div>
    </div>

    <!-- Formación -->
    <div class="max-w-5xl mx-auto space-y-8 text-center">
      <h2 class="text-2xl md:text-3xl font-semibold">
        ¿Por qué elegir esta serie de libros?
      </h2>
      <p class="text-lg md:text-xl">
        Los libros Casa grande, casa chica no son solo guías de organización o
        decoración, son una hoja de ruta para transformar tu hogar en un lugar
        lleno de vida, energía y propósito.
      </p>
      <p class="text-lg md:text-xl">
        Al aplicar lo que aprendas en cada uno de ellos, experimentarás cambios
        profundos en tu espacio y, lo más importante, en ti misma. Esta serie de
        libros está diseñada para que avances paso a paso, desde los conceptos
        más básicos hasta los más avanzados, transformando cada rincón de tu
        hogar en un espacio de bienestar.
      </p>

      <!-- Beneficios -->
      <div
        class="grid gap-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-8"
      >
        <div
          class="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
        >
          <p class="font-bold text-xl mb-3">Fácil de aplicar</p>
          <p>
            No necesitas tener experiencia en diseño ni conocimientos previos de
            Feng Shui. Cada paso está explicado para que puedas implementarlo
            fácilmente en tu hogar.
          </p>
        </div>
        <div
          class="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
        >
          <p class="font-bold text-xl mb-3">Resultados tangibles</p>
          <p>
            No solo sentirás una gran diferencia en tu espacio, sino también en
            tu vida diaria: más energía, más paz, más armonía.
          </p>
        </div>
        <div
          class="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
        >
          <p class="font-bold text-xl mb-3">Metodología probada</p>
          <p>
            el método “3 de 3” ha transformado cientos de hogares y ahora está
            al alcance de tus manos. ¿Lo ponemos en marcha?
          </p>
        </div>
      </div>

      <button
        @click="goToContact"
        class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded transition-transform transform hover:scale-105 mt-8"
      >
        Quiero transformar mi hogar con la guía
      </button>
    </div>

    <!-- Preguntas Frecuentes -->
<div class="max-w-5xl mx-auto text-center space-y-8">
  <h2 class="text-2xl md:text-3xl font-semibold">Preguntas frecuentes</h2>
  <div class="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
    <div
      class="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
    >
      <h3 class="text-lg font-bold text-red-600 mb-2">
        ¿Necesito conocimientos previos de Feng Shui para seguir los libros?
      </h3>
      <p>
        No, estos libros están diseñados para guiarte desde cero. Te llevaré de
        la mano, explicándote cada concepto de forma sencilla y práctica.
      </p>
    </div>
    <div
      class="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
    >
      <h3 class="text-lg font-bold text-red-600 mb-2">
        ¿Puedo aplicar las enseñanzas en cualquier tipo de vivienda?
      </h3>
      <p>
        Sí, los principios que enseño son aplicables tanto si vives en una
        casa grande como en un piso pequeño. Lo importante no es el tamaño
        de tu hogar, sino cómo organizas y armonizas cada espacio.
      </p>
    </div>
    <!-- Última pregunta centrada -->
    <div
      class="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow col-span-2 mx-auto md:w-1/2"
    >
      <h3 class="text-lg font-bold text-red-600 mb-2">
        ¿Cómo puedo saber si mi hogar necesita esta transformación?
      </h3>
      <p>
        Si sientes que en tu hogar falta equilibrio, si la energía parece
        estancada o si quieres que tu casa refleje mejor tus metas y deseos,
        estos libros te darán las herramientas para lograrlo.
      </p>
    </div>
  </div>

  <button
    @click="goToContact"
    class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded transition-transform transform hover:scale-105 mt-8"
  >
    Compra ahora y comienza tu transformación
  </button>
</div>

    <!-- CTA Final -->
    <div class="max-w-5xl mx-auto text-center space-y-8">
      <h2 class="text-2xl md:text-3xl font-semibold">
        Empieza hoy el viaje hacia un hogar lleno de energía
      </h2>
      <p class="text-lg md:text-xl">
        No dejes que tu hogar siga siendo un espacio sin vida. Comienza a tomar
        el control y a transformar cada rincón para que tu casa se convierta en
        un lugar que te inspire, te recargue y te impulse hacia lo que deseas.
      </p>
      <p class="font-bold text-lg md:text-xl">
        Con mis libros, tendrás las herramientas para hacer realidad la
        transformación que tu hogar y tu vida necesitan.
      </p>
      <button
        @click="goToContact"
        class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded transition-transform transform hover:scale-105"
      >
        ¡Quiero los libros!
      </button>
    </div>
  </section>
</template>
