<script>
export default {
  name: "AboutMeComponent",
  methods: {
    goToAboutMe() {
      this.$router.push({ path: '/aboutMe' });
    },
  }
};
</script>

<template>
  <section class="py-16 flex items-center justify-center" id="section5">
    <div class="max-w-5xl mx-auto px-6">
      <h2 data-aos="fade-up" class="text-center text-3xl md:text-4xl font-semibold text-gray-800 mb-12">
        Hola, soy Andrea
      </h2>

      <div data-aos="fade-left" data-aos-duration="1500" class="flex flex-col lg:flex-row items-center lg:items-start gap-16">
        <!-- Imagen de perfil -->
        <div class="w-full lg:w-1/2 flex justify-center lg:justify-start">
          <img src="/aboutme.jpg" alt="Imagen de perfil" class="h-96 w-80 object-cover rounded-lg shadow-lg">
        </div>

        <!-- Descripción personal -->
        <div class="w-full lg:w-1/2 text-center lg:text-left text-gray-700 leading-relaxed">
          <p>
            Con más de 30 años de experiencia, soy especialista en transformar espacios para que reflejen lo mejor de quienes los habitan.
            Mi enfoque combina la sabiduría milenaria del Feng Shui con el diseño interior contemporáneo, creando hogares que no solo se ven bien, sino que vibran con la energía de sus dueños.
          </p>
          <p class="mt-4">
            Desde pequeña, supe que los espacios tienen un impacto profundo en nuestras vidas, y hoy mi misión es enseñarte cómo puedes usar tu hogar como una herramienta para atraer bienestar, equilibrio y abundancia.          </p>
          <p class="mt-4">
            Transformar tu espacio es mucho más que un cambio estético. Es una transformación personal, y estoy aquí para guiarte en ese proceso.
          </p>
          <!-- Botón de llamada a la acción -->
          <div class="mt-8">
            <button @click="goToAboutMe" class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded-lg transition-transform transform hover:scale-105 cursor-pointer">
              Conóceme más aquí
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
