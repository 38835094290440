<template>
  <div class="portfolio" id="home">
    <Section1 />
    <Video />
    <Method />
    <Section2 />
    <Testimonials />
    <AboutMe />
    <Section7 />
    <Section4 />
    <WorkSteps />
    <Section8 />
  </div>
</template>

<script>
import Section1 from "@/components/HomeView/Section1.vue";
import Section2 from "@/components/HomeView/Section2.vue";
import Video from "@/components/HomeView/Video.vue";
import Section4 from '@/components/HomeView/Section4.vue';
import AboutMe from "@/components/HomeView/AboutMe.vue";
import Section7 from "@/components/HomeView/Section7.vue";
import Section8 from "@/components/HomeView/Section8.vue";
import WorkSteps from "@/components/HomeView/WorkSteps.vue";
import Method from "@/components/HomeView/Method.vue";
import Testimonials from "@/components/HomeView/Testimonials.vue";

export default {
  name: "PortfolioView",
  components: {
    Section1,
    Section2,
    Method,
    Testimonials,
    Video,
    Section4,
    AboutMe,
    WorkSteps,
    Section7,
    Section8,
  },
};
</script>
