<script>
export default {
  name: "MethodComponent",
  methods: {
    redirect() {
      this.$router.push('/empower');
    },
  },
};
</script>

<template>
  <section class="bg-slate-100 py-12" id="section4">
    <div class="max-w-6xl px-6 mx-auto">
      <!-- Título y descripción principal -->
      <h2 data-aos="fade-up" class="text-center text-3xl md:text-4xl font-bold pb-6">
        Crea tu espacio interior con el método exclusivo "3 de 3"
      </h2>
      <p data-aos="fade-up" class="text-center text-lg md:text-xl pb-4">
        Tu hogar no es solo un lugar físico. Es tu templo, el espacio donde tu energía fluye y donde se manifiesta tu bienestar. Con mi método "3 de 3", quiero enseñarte a diseñar un entorno que no solo sea funcional, sino que potencie cada aspecto de tu vida.
      </p>
      <h2 data-aos="fade-up" class="text-center text-lg md:text-xl font-bold">
        Desaparecerá el miedo al "no puedo hacerlo" o al "es un lujo", porque descubrirás cómo convertirte en el corazón de tu espacio, reflejando tu verdadera esencia sin que el coste sea un problema.
      </h2>

      <!-- Imagen y tarjetas en columnas iguales -->
      <div class="flex flex-col md:flex-row mt-10 gap-8 items-stretch">
        <!-- Imagen del método (ajustada a la altura de las tarjetas) -->
        <div class="w-full md:w-1/2 flex justify-center">
          <img src="../../assets/3x3-white.jpg" alt="method image" class="w-full h-full max-h-[550px] rounded-lg shadow-lg object-cover">
        </div>

        <!-- Tarjetas de contenido con altura igualada a la imagen -->
        <div class="w-full md:w-1/2 flex flex-col space-y-8">
          <!-- Card 2 -->
          <div class="bg-white p-6 rounded-lg shadow-lg flex-1">
            <h3 class="text-xl font-bold text-red-600 mb-2">Domina tu espacio con mi método "3 de 3"</h3>
            <p class="text-gray-800">
             Mi método "3 de 3" es el resultado de más de 30 años de experiencia, uniendo <span class="font-bold">diseño, Feng Shui y autoayuda</span> para crear hogares que no solo sean bellos, sino que también promuevan el bienestar y el equilibrio.
            </p>
          </div>
          
          <!-- Card 1 -->
          <div class="bg-white p-6 rounded-lg shadow-lg flex-1">
            <h3 class="text-xl font-bold text-red-600 mb-2">Tu hogar como reflejo de tu bienestar</h3>
            <p class="text-gray-800">
              El secreto para una vida plena comienza en casa. Mi misión es ayudarte a transformar tu hogar en un lugar donde la abundancia fluya naturalmente, en todas las áreas de tu vida: desde la salud hasta las relaciones y la prosperidad.             </p>
            <p class="text-gray-800 mt-4">
              ¿Cómo? Utilizando los principios del Feng Shui tradicional y mi método exclusivo de zonificación, circulación de energía y elección de materiales, vamos a reorganizar tu entorno para que trabaje a tu favor. Suena bien, ¿verdad? Ahora te explico el “cómo”.
            </p>
          </div>
        </div>
      </div>

      <!-- Lista de beneficios centrada debajo de la imagen y tarjetas -->
      <div class="text-center mt-16">
        <h3 class="text-xl font-bold text-red-600 mb-4">Mi método te ayudará a:</h3>
        <div class="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-gray-800">
          <!-- Card 1: Zonificación -->
          <div class="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <i class="fas fa-th-large text-red-600 text-3xl mb-4"></i> <!-- Ícono de ejemplo para zonificación -->
            <p>Zonificar tu hogar para que cada área tenga un propósito y potencie diferentes aspectos de tu vida.</p>
          </div>
          <!-- Card 2: Circulación de energía -->
          <div class="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <i class="fas fa-sync-alt text-red-600 text-3xl mb-4"></i> <!-- Ícono de ejemplo para circulación -->
            <p>Optimizar la circulación de energía para que fluya sin obstáculos, generando un entorno armonioso.</p>
          </div>
          <!-- Card 3: Elección de materiales -->
          <div class="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <i class="fas fa-palette text-red-600 text-3xl mb-4"></i> <!-- Ícono de ejemplo para elección de materiales -->
            <p>Elegir los materiales y colores adecuados para cada estancia, reflejando tu esencia y energía en cada rincón.</p>
          </div>
        </div>
      </div>

      <!-- Botón de llamada a la acción -->
      <div class="my-10 text-center">
        <button @click="redirect" class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded-lg transition-transform transform hover:scale-105 cursor-pointer">
          ¡Probemos tu método, Andrea!
        </button>
      </div>
    </div>
  </section>
</template>
