<script>
export default {
  name: "TrainingPage",
  methods: {
    goToContact() {
      this.$router.push({ path: "/contact" });
    },
  },
};
</script>

<template>
  <section class="bg-gray-50 py-12 px-6 space-y-16">
    <!-- Hero Section -->
    <section
      id="inicio"
      class="h-screen bg-gradient-to-r from-red-500 to-red-600 text-white flex items-center justify-center"
    >
      <div class="text-center space-y-6">
        <h1 class="text-4xl md:text-5xl font-extrabold">
          Aprende a crear un hogar que hable de ti
        </h1>
        <h2 class="text-2xl md:text-3xl font-semibold">
          Formaciones personalizadas para que aprendas a transformar tu espacio
        </h2>
        <a
          href="#formacion"
          class="bg-white text-red-600 hover:bg-red-500 hover:text-white font-bold py-3 px-8 rounded-lg transition-transform transform hover:scale-105 mt-8 inline-block"
        >
          ¡Quiero saber más!
        </a>
      </div>
    </section>

    <!-- Introducción -->
    <div class="max-w-4xl mx-auto text-center space-y-8">
      <h2 class="text-4xl font-bold">
        Domina el arte de crear un hogar en armonía
      </h2>
      <p class="text-lg md:text-xl">
        Con mis programas de formación, quiero acompañarte en un viaje de
        autodescubrimiento y transformación, para que aprendas a diseñar tu
        propio espacio y que refleje lo mejor de ti.
      </p>
      <p class="text-lg md:text-xl">
        Estas experiencias están pensadas para ofrecerte herramientas y
        conocimientos duraderos, tanto si prefieres aprender de forma presencial
        como desde la comodidad de tu casa. Te ofrezco distintas opciones que se
        ajustan a tu disponibilidad y tus necesidades.
      </p>
      <p class="text-lg md:text-xl">¿Quieres saber cómo? ¡Sigue leyendo!</p>
    </div>

    <!-- Sección de formación -->
    <div class="max-w-6xl mx-auto space-y-12">
      <h2 id="formacion" class="text-4xl font-bold text-center">
        Empieza tu aprendizaje aquí
      </h2>
      <h3 id="formacion" class="text-2xl font-bold text-center">
        Tu hogar sobre plano
      </h3>
      <p class="text-center text-lg md:text-xl">
        En este taller, te enseñaré a plasmar tus ideas en un plano para
        visualizar mejor los cambios que deseas en tu espacio. No importa si no
        tienes experiencia previa: te guiaré paso a paso para que aprendas a
        dibujar sobre plano y a realizar ajustes de diseño que transformen cada
        rincón de tu hogar.
      </p>

      <div class="bg-gray-50 py-12">
        <!-- Contenedor principal -->
        <div
          class="flex flex-col md:flex-row items-stretch max-w-6xl mx-auto bg-white rounded-lg shadow-md overflow-hidden"
        >
          <!-- Sección de imagen -->
          <div class="w-full md:w-1/2">
            <img
              src="../../assets/plano.jpg"
              alt="Grupo de personas aprendiendo"
              class="w-full h-full object-cover"
            />
          </div>
          <!-- Sección de contenido -->
          <div class="w-full md:w-1/2 p-8 space-y-6">
            <h2 class="text-2xl font-bold">¿Qué aprenderás en este taller?</h2>
            <ul class="space-y-4">
              <li class="flex items-center space-x-3">
                <span class="text-red-600">
                  <!-- Icono de check -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
                <p>Principios básicos de dibujo y diseño sobre plano</p>
              </li>
              <li class="flex items-center space-x-3">
                <span class="text-red-600">
                  <!-- Icono de check -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
                <p>
                  Identificación de áreas clave para la zonificación de espacios
                </p>
              </li>
              <li class="flex items-center space-x-3">
                <span class="text-red-600">
                  <!-- Icono de check -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
                <p>Optimización de cada espacio según sus funciones</p>
              </li>
            </ul>

            <p class="text-gray-600 text-sm">
              <span class="font-bold">Este taller es ideal para </span> quienes
              quieren tener una idea clara de su espacio antes de hacer cambios,
              permitiéndoles planificar y visualizar mejor el resultado final.
            </p>

            <div class="mt-6 space-y-2">
              <p class="font-medium">
                <span class="font-bold">Formato:</span> Presencial
              </p>
              <p class="font-medium">
                <span class="font-bold">Duración:</span> 40 horas
              </p>
            </div>

            <div class="mt-6 space-y-2">
              <button
                @click="goToContact"
                class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded transition-transform transform hover:scale-105"
              >
                Quiero reservar mi plaza
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Beneficios del programa -->
    <div class="max-w-5xl mx-auto space-y-8">
      <h3 class="text-4xl font-bold text-center">Crea tu espacio</h3>
      <p class="text-center text-lg md:text-xl">
        ¿Quieres aprender a armonizar y diseñar tu propio hogar desde cero? En
        mi programa Crea tu espacio, te enseñaré a utilizar técnicas de Feng
        Shui y diseño interior para
        <span class="font-bold text-lg md:text-xl"
          >crear un ambiente que esté en perfecta sintonía contigo y con tus
          objetivos de vida, de principio a fin.</span
        >
      </p>
      <p class="text-center text-lg md:text-xl">
        Este programa está disponible en formato presencial y online,
        adaptándose a tus necesidades.
      </p>

      <div class="bg-gray-50">
        <!-- Contenedor principal -->
        <div
          class="flex flex-col items-center max-w-6xl mx-auto bg-white rounded-lg shadow-md overflow-hidden"
        >
          <!-- Sección de contenido -->
          <div class="w-full p-8 space-y-8 text-center">
            <h2 class="text-3xl font-bold">¿Qué incluye esta formación?</h2>
            <ul class="space-y-8">
              <li class="space-y-2">
                <div class="flex justify-center items-center space-x-3">
                  <span class="text-red-600">
                    <!-- Icono de check -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </span>
                  <p class="font-semibold text-xl">
                    Zonificación y optimización del espacio
                  </p>
                </div>
                <p class="text-lg">
                  Aprende a distribuir cada área de tu hogar según sus funciones
                  y a maximizar su energía.
                </p>
              </li>
              <li class="space-y-2">
                <div class="flex justify-center items-center space-x-3">
                  <span class="text-red-600">
                    <!-- Icono de check -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </span>
                  <p class="font-semibold text-xl">
                    Circulación de energía y Feng Shui
                  </p>
                </div>
                <p class="text-lg">
                  Estrategias para mejorar el flujo de energía en tu hogar,
                  creando un entorno donde te sientas plena y conectada.
                </p>
              </li>
              <li class="space-y-2">
                <div class="flex justify-center items-center space-x-3">
                  <span class="text-red-600">
                    <!-- Icono de check -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </span>
                  <p class="font-semibold text-xl">
                    Selección de materiales y decoración consciente
                  </p>
                </div>
                <p class="text-lg">
                  Cómo elegir los colores, texturas y materiales que resuenan
                  con tu personalidad y estilo de vida.
                </p>
              </li>
            </ul>

            <!-- Formato y duración en línea -->
            <div class="flex justify-center space-x-8 mt-6">
              <p class="font-medium">
                <span class="font-bold text-lg">Formato:</span> Presencial y Online
              </p>
              <p class="font-medium">
                <span class="font-bold text-lg">Duración:</span> 40 horas
              </p>
            </div>

            <!-- Descripción adicional -->
            <p class="text-lg md:text-lg mt-4">
              <span class="font-bold">Esta formación es ideal para</span>
              quienes desean aprender, de manera más completa, a armonizar y
              diseñar cada rincón de su espacio. Podrás crear un hogar a tu
              medida de forma integral, guiándote por técnicas profesionales
              tanto de Feng Shui como de diseño.
            </p>

            <!-- Botón centrado -->
            <div class="mt-8">
              <button
                @click="goToContact"
                class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded transition-transform transform hover:scale-105"
              >
                Quiero reservar mi plaza
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="max-w-5xl mx-auto space-y-8">
      <!-- Título principal -->
      <h3 class="text-4xl font-bold text-center">Sesiones personalizadas</h3>

      <p class="text-center text-lg md:text-xl">
        Te propongo tres formatos de mentoría para elegir el que mejor se adapte
        a ti en función de tus necesidades
      </p>

      <!-- Tarjeta de mentoría -->
      <div
        class="flex flex-col items-center bg-white rounded-lg shadow-md overflow-hidden"
      >
        <div class="w-full p-8 space-y-6">
          <div class="flex justify-center items-center space-x-2">
            <span class="text-red-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 .587l3.668 7.571 8.332 1.151-6.064 5.898 1.432 8.306L12 18.902l-7.368 4.611 1.432-8.306-6.064-5.898 8.332-1.151z"
                />
              </svg>
            </span>
            <h2 class="text-3xl font-bold">
              Sesión de evaluación y plan personalizado
            </h2>
          </div>

          <!-- Descripción del formato -->
          <p class="text-lg md:text-xl">
            <span class="font-bold ">Formato:</span> 2 sesiones mensuales de
            consultoría de 1h cada una conmigo.
          </p>

          <!-- Ideal para -->
          <p class="text-lg md:text-xl">
            <span class="font-bold">Ideal para:</span> Si buscas rediseñar tu
            propio espacio con una hoja de ruta clara y sin dudas durante el
            proceso.
          </p>

          <!-- Cómo funciona -->
          <div class="text-left space-y-4">
            <h3 class="text-lg md:text-xl font-semibold">Cómo funciona:</h3>
            <p class="text-lg md:text-xl">
              En la primera sesión realizo una evaluación de tu hogar y,
              después, en la segunda consultoría te entrego un plan detallado de
              armonización y zonificación para que puedas implementar los
              cambios por tu cuenta.
            </p>
          </div>

          <div class="text-center">
            <!-- Precio -->
            <p class="text-red-600 text-lg font-bold">Precio: 165 €</p>

            <!-- Botón centrado -->
            <div class="mt-3">
              <button
                @click="goToContact"
                class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded transition-transform transform hover:scale-105"
              >
                Quiero mi plan personalizado
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex flex-col items-center bg-white rounded-lg shadow-md overflow-hidden"
      >
        <div class="w-full p-8 space-y-6">
          <div class="flex justify-center items-center space-x-2">
            <span class="text-red-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 .587l3.668 7.571 8.332 1.151-6.064 5.898 1.432 8.306L12 18.902l-7.368 4.611 1.432-8.306-6.064-5.898 8.332-1.151z"
                />
              </svg>
            </span>
            <h2 class="text-3xl font-bold">Sesiones a tu medida</h2>
          </div>

          <p class="text-lg md:text-xl">
            <span class="font-bold">Formato:</span> 4 sesiones personalizadas y quincenales de
            consultoría de 1h cada una conmigo.
          </p>

          <p class="text-lg md:text-xl">
            <span class="font-bold">Ideal para:</span> Si deseas una
            transformación más profunda de tu hogar por tu cuenta y con mi
            acompañamiento constante.
          </p>

          <div class="text-left space-y-4">
            <h3 class="text-lg md:text-xl font-semibold">Cómo funciona:</h3>
            <p class="text-lg md:text-xl">
              Este programa ofrece sesiones quincenales donde te acompaño en la
              implementación del plan de diseño, supervisando cada paso y
              adaptando los cambios según tu evolución y las necesidades de cada
              espacio.
              <span class="font-bold"
                >Incluye soporte por correo electrónico</span
              >
              entre sesiones para resolver cualquier duda.
            </p>
          </div>

          <div class="text-center">
            <p class="text-red-600 text-lg font-bold">Precio: 330 €</p>
            <div class="mt-3">
              <button
                @click="goToContact"
                class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded transition-transform transform hover:scale-105"
              >
                Quiero mi sesiones a medida
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex flex-col items-center bg-white rounded-lg shadow-md overflow-hidden"
      >
        <div class="w-full p-8 space-y-6">
          <div class="flex justify-center items-center space-x-2">
            <span class="text-red-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 .587l3.668 7.571 8.332 1.151-6.064 5.898 1.432 8.306L12 18.902l-7.368 4.611 1.432-8.306-6.064-5.898 8.332-1.151z"
                />
              </svg>
            </span>
            <h2 class="text-3xl font-bold">Mentoría de grupo</h2>
          </div>

          <p class="text-lg md:text-xl">
            <span class="font-bold">Formato:</span> Programa de mentoría grupal
            (máximo 10 personas) de 3 meses con sesiones mensuales de 2h.
          </p>

          <p class="text-lg md:text-xl">
            <span class="font-bold">Ideal para:</span> Personas que disfrutan
            aprendiendo en comunidad y desean trabajar en su propio espacio
            intercambiando conocimientos y consejos con el grupo.
          </p>

          <div class="text-left space-y-4">
            <h3 class="text-lg md:text-xl font-semibold">Cómo funciona:</h3>
            <p class="text-lg md:text-xl">
              Durante tres meses, guiaré a un grupo reducido en el rediseño de
              sus espacios, ofreciendo conceptos de Feng Shui, diseño y
              armonización. Las sesiones incluyen ejercicios prácticos y un
              espacio de preguntas y respuestas, permitiendo que cada
              participante avance en la transformación de su hogar a su propio
              ritmo.
            </p>
          </div>

          <div class="text-center">
            <p class="text-red-600 text-lg font-bold">Precio: 990 €</p>

            <div class="mt-3">
              <button
                @click="goToContact"
                class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded transition-transform transform hover:scale-105"
              >
                Quiero reservar mi plaza
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Beneficios del programa -->
    <div class="max-w-5xl mx-auto space-y-8">
      <h3 class="text-4xl font-bold text-center">Tu espacio en tus manos</h3>
      <p class="text-center text-lg md:text-xl">
        Cada una de estas opciones te permitirá aprender, mejorar y conectar con
        tu espacio de manera única. Si buscas inspiración y conocimiento para
        transformar tu hogar, cualquiera de estos programas será el punto de
        partida perfecto.
      </p>
      <p class="text-center font-bold text-2xl">¿Empezamos?</p>

      <div class="text-center">
        <button
          @click="goToContact"
          class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded transition-transform transform hover:scale-105"
        >
          ¡Empecemos!
        </button>
      </div>
    </div>
  </section>
</template>
