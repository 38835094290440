<script>
export default {
  name: "NavBarComponent",
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    goToHome() {
      this.$router.push({ path: '/' });
    },
    goToBooks() {
      this.$router.push({ path: '/books' });
    },
    goToContact() {
      this.$router.push({ path: '/contact' });
    },
    goToTraining() {
      this.$router.push({ path: '/training' });
    },
    goToAboutMe() {
      this.$router.push({ path: '/aboutme' });
    },
    isActiveRoute(route) {
      return this.$route.path === route;
    }
  }
};
</script>

<template>
  <nav class="bg-white backdrop-blur-lg shadow-md fixed w-full z-20 top-0">
    <div class="container mx-auto flex items-center justify-between px-4 py-3">
      <!-- Logo -->
      <div class="flex justify-center w-full md:w-auto md:justify-start cursor-pointer">
        <img src="../assets/logo-pro-2.jpg" class="h-16 md:h-20" alt="logo" @click="goToHome">
      </div>
      <!-- Botón de menú hamburguesa para dispositivos móviles -->
      <button
        @click="isActive = !isActive"
        class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        aria-controls="navbar-sticky"
        aria-expanded="false"
      >
        <span class="sr-only">Abrir menú principal</span>
        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M3 5h14a1 1 0 110 2H3a1 1 0 110-2zm0 6h14a1 1 0 110 2H3a1 1 0 110-2zm0 6h14a1 1 0 110 2H3a1 1 0 110-2z" clip-rule="evenodd"></path>
        </svg>
      </button>
      <!-- Links de navegación centrados -->
      <div :class="`flex-col md:flex-row ${isActive ? 'flex' : 'hidden'} md:flex w-full md:w-auto`" id="navbar-sticky">
        <ul class="flex flex-col items-center md:items-center p-4 md:p-0 font-bold space-y-2 md:space-y-0 md:space-x-8 md:flex-row">
          <li>
            <a
              @click="goToHome"
              :class="[
                'block py-2 px-3 transition duration-200 cursor-pointer focus:outline-none',
                isActiveRoute('/') ? 'text-red-500 underline' : 'text-black hover:text-red-500 hover:underline'
              ]"
              :style="{ color: isActiveRoute('/') ? '#EF4444' : 'black' }"
            >
              Inicio
            </a>
          </li>
          <li>
            <a
              @click="goToBooks"
              :class="[
                'block py-2 px-3 transition duration-200 cursor-pointer focus:outline-none',
                isActiveRoute('/books') ? 'text-red-500 underline' : 'text-black hover:text-red-500 hover:underline'
              ]"
              :style="{ color: isActiveRoute('/books') ? '#EF4444' : 'black' }"
            >
              Libros
            </a>
          </li>
          <li>
            <a
              @click="goToTraining"
              :class="[
                'block py-2 px-3 transition duration-200 cursor-pointer focus:outline-none',
                isActiveRoute('/training') ? 'text-red-500 underline' : 'text-black hover:text-red-500 hover:underline'
              ]"
              :style="{ color: isActiveRoute('/training') ? '#EF4444' : 'black' }"
            >
              Formación
            </a>
          </li>
          <li>
            <a
              @click="goToAboutMe"
              :class="[
                'block py-2 px-3 transition duration-200 cursor-pointer focus:outline-none',
                isActiveRoute('/aboutme') ? 'text-red-500 underline' : 'text-black hover:text-red-500 hover:underline'
              ]"
              :style="{ color: isActiveRoute('/aboutme') ? '#EF4444' : 'black' }"
            >
              Sobre mí
            </a>
          </li>
          <li>
            <a
              @click="goToContact"
              :class="[
                'block py-2 px-3 transition duration-200 cursor-pointer focus:outline-none',
                isActiveRoute('/contact') ? 'text-red-500 underline' : 'text-black hover:text-red-500 hover:underline'
              ]"
              :style="{ color: isActiveRoute('/contact') ? '#EF4444' : 'black' }"
            >
              Contacto
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
