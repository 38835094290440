<script>
export default {
  name: "WorkStepsComponent",
  methods: {
    goToContact() {
      this.$router.push({ path: '/contact' });
    },
  }
};
</script>

<template>
  <section class="bg-slate-100 py-12" id="workSteps">
    <div class="max-w-6xl px-6 mx-auto">
      <!-- Título principal -->
      <h2 data-aos="fade-up" class="text-center text-3xl md:text-4xl font-bold text-gray-800 mb-10">
        ¿Cómo funciona mi proceso?
      </h2>

      <!-- Pasos en tarjetas -->
      <div class="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <!-- Paso 1 -->
        <div class="bg-white p-6 rounded-lg shadow-lg">
          <p class="font-bold text-2xl text-red-600 mb-2">Paso 1: Diagnóstico de tu espacio</p>
          <p class="text-gray-700 leading-relaxed">
            Empezamos con una evaluación personalizada de tu hogar. Aquí identificaremos los bloqueos energéticos y definiremos qué áreas necesitan reorganización para que la energía fluya libremente.
          </p>
        </div>

        <!-- Paso 2 -->
        <div class="bg-white p-6 rounded-lg shadow-lg">
          <p class="font-bold text-2xl text-red-600 mb-2">Paso 2: Plan de transformación</p>
          <p class="text-gray-700 leading-relaxed">
            Te presentaré un plan detallado basado en el Feng Shui y el diseño interior. Este plan incluirá la zonificación de tu hogar, optimización de la circulación de energía, selección de materiales y colores, y recomendaciones para cada área clave.
          </p>
        </div>

        <!-- Paso 3 -->
        <div class="bg-white p-6 rounded-lg shadow-lg">
          <p class="font-bold text-2xl text-red-600 mb-2">Paso 3: Implementación y seguimiento</p>
          <p class="text-gray-700 leading-relaxed">
            Te acompañaré durante todo el proceso de implementación, asegurándome de que cada cambio refleje tu esencia y potencie tu bienestar. Además, estaré disponible para resolver cualquier duda que surja durante la reorganización.          </p>
        </div>
      </div>

      <!-- Botón de llamada a la acción -->
      <div class="mt-10 text-center">
        <button @click="goToContact" class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded-lg transition-transform transform hover:scale-105 cursor-pointer">
          Reserva tu consulta personalizada
        </button>
      </div>
    </div>
  </section>
</template>
