<script>
export default {
  name: "HomeComponent",
  methods: {
    goToContact() {
      this.$router.push({ path: '/contact' });
    },
  }
};
</script>

<template>
  <section class="bg-slate-100 text-white py-12 relative" id="home">
    <div class="max-w-6xl px-6 mx-auto mt-32 md:mt-56">
      <div data-aos="fade-left" data-aos-duration="1500" class="flex flex-col md:flex-row items-center justify-between">
        <!-- Texto principal -->
        <div class="w-full md:w-2/5 text-center md:text-left mx-auto mb-8 md:mb-0">
          <h1 class="font-bold text-red-600 text-3xl md:text-4xl lg:text-5xl">
            Transforma tu hogar, transforma tu vida
          </h1>
          <div class="mt-6 text-black">
            <h2 class="my-4 text-lg md:text-xl lg:text-2xl">
              Conviértete en el <span class="font-bold">CORAZÓN DE TU ESPACIO</span> y motiva a tu familia a potenciar aún más vuestro bienestar
            </h2>
          </div>
          <div class="mt-6">
            <button @click="goToContact" class="bg-red-600 hover:bg-red-500 text-white font-bold py-3 px-8 rounded-lg transition-transform transform hover:scale-105 cursor-pointer">
              Quiero transformar mi espacio
            </button>
          </div>
        </div>
        
        <!-- Imagen principal sobresaliendo y más grande -->
        <div class="w-full md:w-2/5 flex justify-center md:justify-end relative">
          <img src="/sin_fondo.png" alt="profile image" class="object-cover rounded-lg imgClass">
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
/* Posición y tamaño mayor de la imagen en pantallas grandes */
@media (min-width: 768px) {
  .imgClass {
    position: absolute;
    top: -340px;
    height: 600px; /* Tamaño aumentado */
    width: auto;
  }
}
</style>
